<!--
 * @Description: 建筑资讯
 * @Author: huacong
 * @Date: 2021-06-10 16:50:29
 * @LastEditTime: 2021-08-06 15:11:24
 * @LastEditors: huacong
-->
<template>
  <div class="contentBox">
    <section>
      <!-- 数据展示区域--表格 -->
      <div class="partTimeBox">
        <el-row :gutter="24">
          <el-col :span="17">
            <div class="tabconter">
              <el-tabs v-model="activeName" @tab-click="tabClick">
                <el-tab-pane label="· 资讯首页" name="first"></el-tab-pane>
                <el-tab-pane label="· 考试资讯" name="second"></el-tab-pane>
                <el-tab-pane label="· 人才指南" name="third"></el-tab-pane>
                <el-tab-pane label="· 猎头指导" name="fourth"></el-tab-pane>
                <el-tab-pane label="· 薪酬福利" name="fiveth"></el-tab-pane>
              </el-tabs>
            </div>
            <el-table :data="partData" class="partTimeTable">
              <el-table-column width="170px">
                <template slot-scope="scope">
                  <div>
                    <img
                      style="width: 160px;"
                      @click="goDetail(scope.row.id)"
                      :src="
                        scope.row.content.match(/http.*?(?:jpg|\/>)/gi) != null
                          ? scope.row.content.match(/http.*?(?:jpg|\/>)/gi)
                          : zixunImg
                      "
                      alt=""
                    />
                  </div>
                </template>
              </el-table-column>
              <el-table-column>
                <template slot-scope="scope">
                  <div style="padding: 5px">
                    <span
                      class="zixuxtableTitle fontHover"
                      @click="gozixun(scope.row.id)"
                    >
                      {{ scope.row.title }}
                    </span>
                    <span style="float: right;">
                      {{ scope.row.push_time }}
                    </span>
                  </div>
                  <div>
                    <div class="zixunContent" v-html="scope.row.content"></div>
                  </div>
                  <div style="padding-top:5px;">发布人：小奕</div>
                </template>
              </el-table-column>

              <div slot="empty" class="empty">
                <div>
                  <img :src="noDate" />
                </div>
                <span>暂无数据</span>
              </div>
            </el-table>
            <div class="pageBox">
              <el-pagination
                background
                @size-change="sizeChange1"
                @current-change="currentChange1"
                :current-page="currentPage"
                :page-size="pagesize"
                layout="total, prev, pager, next, jumper"
                :total="tableTotal"
              >
              </el-pagination>
            </div>
          </el-col>
          <el-col :span="7">
            <div style="padding: 10px 0">
              <span class="font-blue" style="font-size: 17px;font-weight:bold">
                I
              </span>
              <span style="font-size: 17px;font-weight:bold">热门文章</span>
              <span class="font-blue replaceBtn" @click="replaceHot">
                <i class="el-icon-refresh-right" style="font-size: 16px;"></i>
                换一批
              </span>
            </div>
            <el-table :data="partData2" class="partTimeTable">
              <el-table-column>
                <template slot-scope="scope">
                  <div>
                    <span
                      class="zixuxtableTitle fontHover"
                      @click="gozixun(scope.row.id)"
                    >
                      {{ scope.row.title }}
                    </span>
                  </div>
                  <div>
                    <div class="zixunContent" v-html="scope.row.content"></div>
                  </div>
                  <div>
                    发布人：小奕
                    <span style="float: right;">
                      {{ scope.row.push_time }}
                    </span>
                  </div>
                </template>
              </el-table-column>

              <div slot="empty" class="empty">
                <div>
                  <img :src="noDate" />
                </div>
                <span>暂无数据</span>
              </div>
            </el-table>
            <!-- <div class="pageBox">
              <el-pagination
                background
                @size-change="sizeChange2"
                @current-change="currentChange2"
                :current-page="currentPage2"
                :page-size="pagesize2"
                layout="total, prev, pager, next, jumper"
                :total="tableTotal2"
              >
              </el-pagination> 
            </div>-->
          </el-col>
        </el-row>
      </div>
    </section>
  </div>
</template>
<script>
// import $ from "jquery";
export default {
  name: "aptitudeIndex",
  components: {},
  data() {
    return {
      noDate: require("@/assets/img/noDate.png"),
      zixunImg: require("@/assets/img/zixun.png"),
      searchInfo: {},
      searchInfo2: {},
      partData: [],
      currentPage: 1,
      pagesize: 10,
      tableTotal: 0,
      partData2: [],
      currentPage2: 1,
      pagesize2: 5,
      tableTotal2: 0,
      activeName: "first",
    };
  },
  methods: {
    // 获取大列表数据
    getParttimeList() {
      this.searchInfo.page = this.currentPage;
      this.searchInfo.limit = this.pagesize;
      this.$http.post("index/home/getinfo", this.searchInfo).then((res) => {
        if (res.data.resultCode === 1) {
          this.partData = res.data.resultData.list;
          this.tableTotal = parseInt(res.data.resultData.count);
        }
      });
    },
    sizeChange1(e) {
      this.pagesize = e;
      this.currentPage = 1;
      this.getParttimeList();
    },
    currentChange1(e) {
      this.currentPage = e;
      this.getParttimeList();
    },
    // 小列表数据
    getParttimeList2() {
      this.searchInfo2.page = this.currentPage2;
      this.searchInfo2.limit = this.pagesize2;
      this.searchInfo2.category = 1;
      this.$http.post("index/home/getinfo", this.searchInfo2).then((res) => {
        if (res.data.resultCode === 1) {
          this.partData2 = res.data.resultData.list;
          this.tableTotal2 = parseInt(res.data.resultData.count);
        }
      });
    },
    sizeChange2(e) {
      this.pagesize2 = e;
      this.currentPage2 = 1;
      this.getParttimeList2();
    },
    currentChange2(e) {
      this.currentPage2 = e;
      this.getParttimeList2();
    },
    // 切换资讯类型
    tabClick(tab) {
      if (tab.$options.propsData.name == "first") {
        this.searchInfo.category = "";
        this.currentPage = 1;
      }
      if (tab.$options.propsData.name == "second") {
        this.searchInfo.category = "2";
        this.currentPage = 1;
      }
      if (tab.$options.propsData.name == "third") {
        this.searchInfo.category = "3";
        this.currentPage = 1;
      }
      if (tab.$options.propsData.name == "fourth") {
        this.searchInfo.category = "4";
        this.currentPage = 1;
      }
      if (tab.$options.propsData.name == "fiveth") {
        this.searchInfo.category = "5";
        this.currentPage = 1;
      }
      this.getParttimeList();
    },
    // 换一批
    replaceHot() {
      if (this.currentPage2 == 1) {
        this.currentPage2 = 2;
      } else if (this.currentPage2 == 2) {
        this.currentPage2 = 1;
      }
      this.getParttimeList2();
    },
    // 资讯详情
    gozixun(id) {
      let routeUrl = this.$router.resolve({
        name: "consultingDetail",
        query: { id: id },
      });
      window.open(routeUrl.href, "_blank");
    },
  },
  mounted() {
    this.getParttimeList();
    this.getParttimeList2();
  },
};
</script>
<style scoped>
.tabconter {
  background: #fff;
  padding: 10px;
  padding-bottom: 0;
}
/deep/ .tabconter .el-tabs__item {
  font-weight: bold;
}
/deep/ .tabconter .el-tabs__active-bar {
  opacity: 0;
}
.zixuxtableTitle {
  font-size: 17px;
  font-weight: bold;
  color: #333;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.zixunContent {
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  height: 50px;
}
.replaceBtn {
  float: right;
}
.replaceBtn:hover {
  cursor: pointer;
}
.replaceBtn:hover i {
  transform: rotate(360deg);
  transition: 0.4s ease-in-out;
}
/deep/ .partTimeTable .el-table__header-wrapper {
  height: 0;
}
</style>
